<template>
<div
    class="relative "
    @mouseenter="tooltipOpen = true"
    @mouseleave="tooltipOpen = false"
    @focusin="tooltipOpen = true"
    @focusout="tooltipOpen = false"
  >
    <button
      class="block"
      aria-haspopup="true"
      :aria-expanded="tooltipOpen"
      @click.prevent
    >
      <slot name="tipped">
        <svg class="w-4 h-4 fill-current text-slate-400" viewBox="0 0 16 16">
          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
        </svg>
      </slot>
    </button>
    <div class="z-10 absolute" :class="positionOuterClasses(position)">
      <transition
        enter-active-class="transition ease-out duration-200 transform"
        enter-from-class="opacity-0 -translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-out duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="tooltipOpen" class="rounded text-center text-xs"
          :class="[
            bg === 'dark' ? 'bg-dark-900 text-white font-normal' : 'bg-white text-dark-900 border border-slate-200 shadow-lg',
            sizeClasses(size),
            positionInnerClasses(position),
            bg === 'dark' && position === 'top' ? 'dark-arrow-bottom' : '',
            bg === 'dark' && (position === 'bottom') ? 'dark-arrow-top' : ''
          ]"
        >
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  position: {
    type: String,
    default: 'bottom'
  },
  size: {
    type: String,
    default: 'md'
  },
  bg: {
    type: String,
    default: 'light'
  }
});

const tooltipOpen = ref(false);

const positionOuterClasses = (position) => {
  switch (position) {
    case 'right':
      return 'left-full top-1/2 -translate-y-1/2';
    case 'left':
      return 'right-full top-1/2 -translate-y-1/2';
    case 'bottom':
      return 'top-full left-1/2 -translate-x-1/2';
    default:
      return 'bottom-full left-1/2 -translate-x-1/2';
  }
}

const sizeClasses = (size) => {
  switch (size) {
    case 'xl':
      return 'w-96 p-3';
    case 'lg':
      return 'w-72  p-3';
    case 'md':
      return 'w-56 p-3';
    case 'sm':
      return 'w-44 p-2';
    case 'xs':
      return 'w-20 p-1';
    default:
      return 'p-2';
  }
}

const positionInnerClasses = (position) => {
  switch (position) {
    case 'right':
      return 'ml-2';
    case 'left':
      return 'mr-2';
    case 'bottom':
      return 'mt-2';
    default:
      return 'mb-2';
  }
}

</script>

<style scoped>

</style>